import newsDetail from '@/views/urbanManageNews/newsDetail.vue'
import API from '@/api/index'
const { getProblemDetail } = API

export default {
  mixins: [newsDetail],
  data () {
    return {
      title: '常见问题'
    }
  },
  methods: {
    async getDiscloseDetail () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let newsId = this.$route.params.id
      let res = await getProblemDetail({ url: '/ps/question/' + newsId }).catch(() => {
        loading.clear()
      })

      loading.clear()

      if (res.success) {
        this.detail = {
          newsTitle: res.data.questionTitle,
          newsPromulgator: res.data.publisher,
          publishTime: res.data.publishTime,
          content: res.data.questionContent
        }
      } else {
        this.$toast(res.message)
      }
    }
  }
}
